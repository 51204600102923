import React from 'react';
import { Link, useParams } from "react-router-dom";

import JudeteDataService from '../services/JudeteDataService';
import Message from '../message/Message';
import unixTimeToDateString from '../services/Date'

import './LicitatieDesktop.css';

class ButtonSave extends React.Component {

    save( licitatieId ) {
        JudeteDataService.saveLicitatie(licitatieId).then( response => {
            if( response.added === 1) {
                this.props.onMessage("Salvata");
            }
            else {
                this.props.onMessage("A fost o eroare");
            }
        });

        this.props.onUpdateReqested();
    }

    removeSaved( licitatieId ) {
        JudeteDataService.removeLicitatie(licitatieId).then( response => {
            if ( response.removed === 1) {
                this.props.onMessage("Stearsa");
            }
            else {
                this.props.onMessage("A fost o eroare");
            }
        });
        this.props.onUpdateReqested();
    }

    render() {

        if ( this.props.salvata === 1 ) {
            return (
                <div id="save-btn" className="details-btn desktop unselectable" onClick={() => { this.removeSaved(this.props.licitatieId) }}>Sterge</div>
            )
        }
        else {
            return (
                <div id="save-btn" className="details-btn desktop unselectable" onClick={() => { this.save(this.props.licitatieId) }}>Interesanta</div>
            )
        }
    }
}

class ButonRaporteaza extends React.Component {

    raporteaza( licitatieId ) {
        JudeteDataService.raporteazaLicitatie(licitatieId).then( response => {
            if( response.added === 1) {
                this.props.onMessage("Raportata");
            }
            else {
                this.props.onMessage("A fost o eroare");
            }

            this.props.onUpdateReqested();
        });
    }


    render() {

        if ( this.props.raportata === 0 ) {
            return (
                <div id="report-btn" className="details-btn desktop unselectable" onClick={() => { this.raporteaza(this.props.licitatieId) }}>Gresita</div>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    }
}

class LicitatieDesktop extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            totalPrice: 0,
            priceMp: 0,
            message: ""
        }
    }

    componentDidMount() {
        const licitatieId  = this.props.params.licitatieId;

        JudeteDataService.getLicitatie(licitatieId).then( response => {
            this.setState({
                id: response.id,
                title: response.title,
                totalPrice: response.total_price,
                priceMp: response.price_mp,
                priceUnit: response.price_unit,
                surface: response.surface,
                judet: response.judet,
                dataScanare: unixTimeToDateString(response.data_scanare),
                dataLicitatie: response.data_licitatie,
                detalii: response.detalii,
                salvata: response.salvata,
                raportata: response.raportata,
                pdf: response.pdf,
                content: response.content
            });

            // Salvez detele pentru a sti ce judet sa deschid in pagina principala
            JudeteDataService.dropJud = response.judet;

            // Salvez id-ul pentru a sti ce rand sa marchez in dropdown
            JudeteDataService.lastDropdownSelectedId = this.props.params.licitatieId;

            // Updatez si licitatiile din lista licitatiiAll daca este cazul
            const inList = JudeteDataService.licitatiiAll.find(l => l.id === response.id);
            if ( !!inList ) {
                inList.salvata = response.salvata;
            }

        }, reason => {
            console.error(reason);
        });
    }


    downloadPdf( id, url ) {
        let a  = document.createElement('a');
        a.href = url;
        a.download = id + '.pdf';
        a.click();
    }

    updateRequested() {
        JudeteDataService.lastGetRaportDataJudet = null;
        this.componentDidMount();
    }

    openDetaliiLink(detaliiLink) {
        window.open( detaliiLink );
    }

    showMessage(message) {
        this.setState({
            message: message
        });
    }

    closeMessage() {
        this.setState({
            message: ""
        });
    }

    elementDataLabel() {
        if ( !this.state.dataLicitatie || this.state.dataLicitatie.length === 0 ) {
            return ( <div className="property">Data scanare</div> )
        }
        else {
            return ( <div className="property">Data licitatie</div> )
        }
    }

    elementDataValoare() {
        if ( !this.state.dataLicitatie || this.state.dataLicitatie.length === 0 ) {
            return ( <div className='value'>{ this.state.dataScanare }</div> )
        }
        else {
            return ( <div className='value'>{ this.state.dataLicitatie}</div>)
        }
    }


    render() {

        return (
            <div id="content">
                <Link id="link" to={`/`}><div  id="inapoi-btn">〈 Lista judete</div></Link>

                <div id="properties-container-desktop">

                    <div></div>

                    <div>
                        {/* Titlul */}
                        <div id="title-licitatie" className="desktop" >{this.state.title}</div>

                        <div id="properties-desktop">
                            <div className="property">Total price</div>
                            <div className='value'> { this.state.totalPrice } {this.state.priceUnit}</div>

                            <div className="property">Price MP</div>
                            <div className='value'> { parseFloat(this.state.priceMp).toFixed(2) }</div>

                            <div className="property">Surface </div>
                            <div className='value'>{ this.state.surface }</div>

                            <div className="property">Judet </div>
                            <div className='value'>{ this.state.judet }</div>

                            {/* <div className="property">Data scanare </div> */}
                            {/* <div className='value'>{ this.state.dataScanare }</div> */}
                            { this.elementDataLabel() }
                            { this.elementDataValoare() }

                            <div className='property'>Id</div>
                            <div className='value'> { this.state.id }</div>
                        </div>

                        <div id="details-buttons-desktop">

                            <div className="details-btn desktop" onClick={() => {this.openDetaliiLink(this.state.detalii) }}>Deschide</div>

                            { !!this.state.pdf && <div className="details-btn desktop" onClick={() => {this.downloadPdf(this.state.id, this.state.pdf) }}>Publicatie</div> }

                            <ButtonSave salvata={this.state.salvata} 
                                licitatieId={this.state.id} 
                                onUpdateReqested={() => this.updateRequested()} 
                                onMessage={(mes) => this.showMessage(mes)} />

                            <ButonRaporteaza raportata={this.state.raportata} 
                                licitatieId={this.state.id} 
                                onUpdateReqested={() => this.updateRequested() } 
                                onMessage={(mes) => this.showMessage(mes)} />
                        </div>
                    </div>


                    <div></div>

                </div>

                { !!this.state.content && <div id="details-content-desktop">{this.state.content}</div>}

                <Message message={this.state.message} 
                    show={this.state.message.length > 0} 
                    onClose={ () => this.closeMessage() } />

            </div>
        )
    }
}

// eslint-disable-next-line
export default (props) => (
    <LicitatieDesktop {...props} params={useParams()} /> 
);