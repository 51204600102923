import React from 'react';
import { Link } from "react-router-dom";

import './MapMobile.css'
import JudeteDataService from './services/JudeteDataService';
import unixTimeToDateString from './services/Date';
import config from './config';
import HomeButtons from './homeButtons/HomeButtons';


class HeaderSection extends React.Component {

    render() {
        return (
            <div id="header-container"  className='unselectable'>
                <img id="company-logo" alt="company-logo" src="logo-dark192.png" />
                <div id="titles-column">
                    <div id="cautare-dosare-subtitle">LICITATII IMOBILIARE</div>
                    <div id="version">{config.version}</div>
                </div>
            </div>
        );
    }
}

class TotaluriMobil extends React.Component {

    render() {
        return (
            <div id="totaluri-mobil-container" className='unselectable'>
                <div id="title">Total la data { unixTimeToDateString(this.props.data) }</div>
                <div id="anunturi">{this.props.anunturi} Anunturi</div>
                <div id="licitatii">{this.props.anunturiAzi} Anunturi astazi</div>
                <div id="licitatii">{this.props.licitatii} Licitatii astazi</div>
            </div>
        )
    }
}

class Salvata extends React.Component {
    render() {
        if ( this.props.salvata === 1 ) {
            return (
                <span className="interesanta">I</span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}

class Raportata extends React.Component {
    render() {
        if ( this.props.raportata === 1 ) {
            return (
                <span className="raportata">R</span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}


class DetailsDropDown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            licitatiiSelectate: [],
            licitatiiAll: []
        }
    }

    componentDidMount() {
        // Incerc sa iau din cache in loc sa mai fac un nou request
        if (!!JudeteDataService.lastGetRaportDataJudet && JudeteDataService.lastGetRaportDataJudet.judet === this.props.judet) {
            this.setState({
                licitatiiSelectate: JudeteDataService.lastGetRaportDataJudet.licitatii_selectate,
                licitatiiAll: JudeteDataService.lastGetRaportDataJudet.licitatii_all
            });

            JudeteDataService.licitatiiAll = JudeteDataService.lastGetRaportDataJudet.licitatii_all;
            JudeteDataService.licitatiiAllAnunturiCount = JudeteDataService.lastGetRaportDataJudet.anunturi_count;
            JudeteDataService.licitatiiAllJud = this.props.judet;
        }
        else {
            /**
             * Response contine datele unui raport pentru un judet
             * judet = ""
             * licitatii_selectate  - [ {  id: 0, price_mp: 0, detalii: "", salvata: 1 sau 0, "raportata": 1 sau 0 }, ... ]
             * licitatii_all        - lista cu obiecte licitatii simplificate: LicitatieSimplificataDetails
             * anunturi_count       - numarul de anunturi din baza de date, pentru judetul selectat
             */
            JudeteDataService.getRaportDataJudet(this.props.judet).then( response => {
                this.setState({
                    licitatiiSelectate: response.licitatii_selectate,
                    licitatiiAll: response.licitatii_all
                });
                JudeteDataService.lastGetRaportDataJudet = response;
                JudeteDataService.licitatiiAll = response.licitatii_all;
                JudeteDataService.licitatiiAllJud = this.props.judet;
                JudeteDataService.licitatiiAllAnunturiCount = response.anunturi_count;
            });
        }

    }

    downloadReport(judet) {
        JudeteDataService.downloadReport(judet).then( response => {

            if ( response.status !== 200) {
                alert("Not posible. Server error");
                return;
            }

            response.blob().then( blob => {
                let url = window.URL.createObjectURL(blob);
                let a  = document.createElement('a');
                a.href = url;
                a.download = judet + '.pdf';

                // Salvez detele pentru a sti ce judet sa deschid in pagina principala
                JudeteDataService.dropJud = judet;

                a.click();
            })
        }, (reason) => {
            alert("Not posible. " + reason);
        });
    }

    isLastSelected(licitatieId) {
        return parseInt(JudeteDataService.lastDropdownSelectedId) === parseInt(licitatieId);
    }

    render() {
        return (
            <div id="dropdown-container">
                <div id="anunturi">{this.props.anunturi} Anunturi</div>
                <div id="licitatii">{this.props.licitatii} Licitatii</div>
                { this.state.licitatiiSelectate.map( (l, index) => (

                    <Link id="link" key={index} to={`/id/${l.id}`} >
                        <div className='licitatie-selectata' { ...this.isLastSelected(l.id) && { style: {background: '#5f1000'}}}>
                            <span className='licitatie-id'>{l.id}</span> 
                            <span className="pret">{ parseFloat(l.price_mp).toFixed(2) + " EUR/mp" }</span>
                            <Salvata salvata={l.salvata}/>
                            <Raportata raportata={l.raportata}/>
                        </div>
                    </Link>
                     
                ))}
                <div id="raport-button" onClick={() => this.downloadReport( this.props.judet )}>Descarca raport</div>
                <Link id="link-toate" to={`/judet/${this.props.judet}`}>
                    <div id="toate-button" >Toate</div>
                </Link>
            </div>
        )
    }
}

class JudeteMobil extends React.Component {

    // Jud este de forma
    // nume = ""
    // anunturi = 0
    // licitatii = 0
    // enabled = 0 
    handleJudetClick(jud) {
        return (e) => {
            // Trimit la Map Component, judetul pe care s-a facut click si pozitia cursorului
            this.props.onClick(jud);
        }
    }

    cssClasses(jud) {
        let cssString = "";

        if ( jud.nume === this.props.judet ) {
            cssString += "name-selected ";
        }
        else {
            cssString += "name-not-selected ";
        }

        if ( jud.enabled === 1) {
            cssString += "enabled";
        }
        else {
            cssString += "not-enabled";
        }

        return cssString;
    }

    isSelected(jud) {
        return jud === this.props.details.judet;
    }

    render() {
        // Judet este un obiect de forma { nume: "nume", anunturi: 0, licitatii: 0 }
        return (
            <div id="judete-container" className='unselectable' >
                {this.props.judete.map( (judet, index) => (
                    <div key={index} >
                        <div className="judet-row"  onClick={this.handleJudetClick(judet)} >
                            <div className={ this.cssClasses(judet) } >{judet.nume}</div>
                            <div className="licitatii">{judet.licitatii}</div> 
                        </div>

                        { this.isSelected(judet.nume) && 
                            <DetailsDropDown 
                                    judet={judet.nume}
                                    anunturi={this.props.details.jud_anunturi} 
                                    licitatii={this.props.details.jud_licitatii} /> }

                    </div>
                ))}
            </div>
        );
    }
}

class MapMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataScanare: 0,
            numarAnunturi: 0,
            numarAnunturiAzi: 0,
            numarLicitatii: 0,
            judetSelectat: "",
            judete: [],
            details: {
                judet: "selecteaza judet",
                jud_anunturi: 0,
                jud_licitatii: 0
            },
            licitatiiSalvate: []
        }
    }

    sendEvent = () => {
        if(window.location.hostname === 'localhost' || window.location.hostname.indexOf('192.168.0') === 0) {
            return;
        }

        if(!sessionStorage.getItem('visit')) {
            sessionStorage.setItem('visit', true);

            fetch('https://api.ipify.org?format=json')
                .then((ipResp) => ipResp.json().then((ipJson) => {
                    fetch('https://09mclyfv7k.execute-api.eu-central-1.amazonaws.com/prod/log_event', {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({type: 'visit', name: 'licitatii.binaryfusion.ro', from: ipJson}),
                    }).then((response) => response.json().then((responseData) => {
                        sessionStorage.setItem('success', responseData.EventId);
                    }));
                })
            );
        }
    }

    componentDidMount() {
        this.sendEvent();

        /**
         * Judete este o lista cu obiecte de forma
         * nume = ""
         * anunturi = 0
         * licitatii = 0
         * enabled = 0 este 1 doar cand pot descarca raportul
         */
        JudeteDataService.getJudData().then( response => {
            JudeteDataService.lastResponse = response;

            // Resetez id-ul licitatiei selectate sa nu coloreze rand din lista totala de licitatii
            JudeteDataService.licitatiiAllSelected = 0;

            // Filtrez judetele care nu au nume
            let judete = response.judete
            judete = judete.filter(j => j.nume.trim() !== "")
            judete = judete.filter(j => j.nume.trim() !== "jud.")

            this.setState({
                dataScanare: response.data_scanare,
                numarAnunturi: response.numar_anunturi,
                numarAnunturiAzi: response.numar_anunturi_azi,
                numarLicitatii: response.numar_licitatii,
                licitatiiSalvate: response.licitatii_salvate,
                judete: judete
            });

            // Redeschid ultimul judet selectat. Cazul in care revin de la pagina de detalii: Licitatie.js
            if ( !!JudeteDataService.dropJud ) {
                this.onJudetClick( { nume: JudeteDataService.dropJud } );
            }

        });
    }


    // Jud este de forma
    // nume = ""
    // anunturi = 0
    // licitatii = 0
    // enabled = 0 
    onJudetClick(jud) {

        if ( jud.enabled === 0 ) {
            // Daca nu este activ nu fac nimic
            return;
        }
        if ( this.state.details.judet === jud.nume ) {
            // Daca este selectat, il deselectez
            this.setState({ 
                judetSelectat: null,
                details: {
                    judet: null,
                    jud_anunturi: 0,
                    jud_licitatii: 0
                }
            });
        }
        else {
            let jud_data = this.state.judete.find( j => j.nume.indexOf(jud.nume) !== -1)

            if ( !jud_data ) {
                jud_data = { anunturi: 0, licitatii: 0 }
            }

            this.setState({ 
                judetSelectat: jud.nume,
                details: {
                    judet: jud.nume,
                    jud_anunturi: jud_data.anunturi,
                    jud_licitatii: jud_data.licitatii
                }
            });
        }
    }

    render() {
        return (
            <div>
                <HeaderSection />

                <TotaluriMobil 
                    data={this.state.dataScanare}
                    anunturi={this.state.numarAnunturi} 
                    anunturiAzi={this.state.numarAnunturiAzi}
                    licitatii={this.state.numarLicitatii} />

                <HomeButtons />

                <JudeteMobil 
                    judete={ this.state.judete} 
                    onClick={ jud => this.onJudetClick(jud) }
                    details={ this.state.details }
                    salvate={ this.state.licitatii }  />
            </div>
        )
    }

}

export default MapMobile;
