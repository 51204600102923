const dev = {
    version: "3.8",
    testVar: "testVar for dev environment"
}

const prod = {
    version: "3.8",
    testVar: "testVar for prod environment"
}

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default config;
