import React from 'react';
import { Stage, Layer, Text, Image } from 'react-konva';

import './Map.css';
import JudeteDataService from './services/JudeteDataService';
import LicitatiiSelectateDesktop from './licitatiiSelectateDesktop/licitatiiSelectateDesktop';
import JudDetailsPanel from './JudDetailsPanel';
import TotalAnunturi from './TotalAnunturi';
import CountersDesktop from './countersDesktop/CountersDesktop';


// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded
class URLImage extends React.Component {
    state = {
        image: null
    };
    componentDidMount() {
        this.loadImage();
    }
    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }
    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }
    loadImage() {
        // save to "this" to remove "load" handler on unmount
        this.image = new window.Image();
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad);
    }
    handleLoad = () => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
        this.setState({
            image: this.image
        });
        // if you keep same image object during source updates
        // you will have to update layer manually:
        // this.imageNode.getLayer().batchDraw();
    };

    render() {
        return (
            <Image
                x={this.props.x}
                y={this.props.y}
                image={this.state.image}
                ref={node => {
                    this.imageNode = node;
                }}
                onClick={this.props.onClick}
                opacity={this.props.selected ? 1 : 0.2 }
            />
        );
    }
}

/**
 * Reprezinta toata harta romaniei desenata in canvas si textele cu numele judetelor
 */
class Judete extends React.Component {

    handleJudetClick(jud) {
        return (e) => {
            // Trimit la Map Component, judetul pe care s-a facut click si pozitia cursorului
            this.props.onClick(jud);
        };
    }

    isSelected(judImage) {
        return judImage === this.props.judetSelectat;
    }

    textOpacity() {
         if ( this.props.judetSelectat === "" ) {
             return 1;
         }
         else {
             return 0.2;
         }
    }

    counters(numeJudet) {
        if (!this.props.judete.find(jud => jud.nume === numeJudet)) {
            return {
                anunturi: 0,
                licitatii: 0
            }
        }

        return {
            anunturi: this.props.judete.find(jud => jud.nume === numeJudet).anunturi,
            licitatii: this.props.judete.find(jud => jud.nume === numeJudet).licitatii
        }
    }

    render() {
        return (
            <Stage width={1800} height={1500}>
                <Layer>
                    <URLImage id="judet-selectat-image" src="romania.svg" x={200} y={200} selected={ this.props.judetSelectat === "" } />

                    <Text text="SM" x={555} y={290} onClick={ this.handleJudetClick("satumare")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() }/>
                    <CountersDesktop x={555} y={290} anunturi={this.counters("satumare").anunturi} licitatii={this.counters("satumare").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("satumare")} />

                    <Text text="MM" x={710} y={290} onClick={ this.handleJudetClick("maramures")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() }/>
                    <CountersDesktop x={710} y={290} anunturi={this.counters("maramures").anunturi} licitatii={this.counters("maramures").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("maramures")} />

                    <Text text="BN" x={790} y={380} onClick={ this.handleJudetClick("bistritanasaud")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() }/>
                    <CountersDesktop x={790} y={380} anunturi={this.counters("bistritanasaud").anunturi} licitatii={this.counters("bistritanasaud").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("bistritanasaud")} />

                    <Text text="TM" x={330} y={670} onClick={ this.handleJudetClick("timis")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() }/>
                    <CountersDesktop x={330} y={670}  anunturi={this.counters("timis").anunturi} licitatii={this.counters("timis").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("timis")} />

                    <Text text="AR" x={400} y={560} onClick={ this.handleJudetClick("arad")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={400} y={560}  anunturi={this.counters("arad").anunturi} licitatii={this.counters("arad").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("arad")} />

                    <Text text="BH" x={460} y={425} onClick={ this.handleJudetClick("bihor")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={460} y={425}  anunturi={this.counters("bihor").anunturi} licitatii={this.counters("bihor").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("bihor")} />

                    <Text text="CJ" x={670} y={470} onClick={ this.handleJudetClick("cluj")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={670} y={470}  anunturi={this.counters("cluj").anunturi} licitatii={this.counters("cluj").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("cluj")} />

                    <Text text="AM" x={640} y={590} onClick={ this.handleJudetClick("alba")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={640} y={590}  anunturi={this.counters("alba").anunturi} licitatii={this.counters("alba").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("alba")} />

                    <Text text="HD" x={560} y={680} onClick={ this.handleJudetClick("hunedoara")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={560} y={680}  anunturi={this.counters("hunedoara").anunturi} licitatii={this.counters("hunedoara").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("hunedoara")} />

                    <Text text="SB" x={745} y={670} onClick={ this.handleJudetClick("sibiu")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={745} y={670}  anunturi={this.counters("sibiu").anunturi} licitatii={this.counters("sibiu").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("sibiu")} />

                    <Text text="MH" x={550} y={940} onClick={ this.handleJudetClick("mehedinti")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={550} y={940}  anunturi={this.counters("mehedinti").anunturi} licitatii={this.counters("mehedinti").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("mehedinti")} />

                    <Text text="GJ" x={610} y={830} onClick={ this.handleJudetClick("gorj")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={610} y={830}  anunturi={this.counters("gorj").anunturi} licitatii={this.counters("gorj").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("gorj")} />

                    <Text text="DJ" x={650} y={1000} onClick={ this.handleJudetClick("dolj")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={650} y={1000}  anunturi={this.counters("dolj").anunturi} licitatii={this.counters("dolj").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("dolj")} />

                    <Text text="OT" x={775} y={980} onClick={ this.handleJudetClick("olt")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={775} y={980}  anunturi={this.counters("olt").anunturi} licitatii={this.counters("olt").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("olt")} />

                    <Text text="VL" x={730} y={820} onClick={ this.handleJudetClick("valcea")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={730} y={820}  anunturi={this.counters("valcea").anunturi} licitatii={this.counters("valcea").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("valcea")} />

                    <Text text="CS" x={430} y={790} onClick={ this.handleJudetClick("caras")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={430} y={790}  anunturi={this.counters("caras").anunturi} licitatii={this.counters("caras").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("caras")} />

                    <Text text="SJ" x={590} y={390} onClick={ this.handleJudetClick("salaj")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={590} y={390}  anunturi={this.counters("salaj").anunturi} licitatii={this.counters("salaj").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("salaj")} />

                    <Text text="SV" x={960} y={310} onClick={ this.handleJudetClick("suceava")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={960} y={310}  anunturi={this.counters("suceava").anunturi} licitatii={this.counters("suceava").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("suceava")} />

                    <Text text="MS" x={810} y={510} onClick={ this.handleJudetClick("mures")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={810} y={510}  anunturi={this.counters("mures").anunturi} licitatii={this.counters("mures").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("mures")} />

                    <Text text="HR" x={930} y={520} onClick={ this.handleJudetClick("harghita")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={930} y={520}  anunturi={this.counters("harghita").anunturi} licitatii={this.counters("harghita").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("harghita")} />

                    <Text text="BV" x={890} y={675} onClick={ this.handleJudetClick("brasov")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={890} y={675}  anunturi={this.counters("brasov").anunturi} licitatii={this.counters("brasov").licitatii}  opacity={ this.textOpacity() }  onClick={ this.handleJudetClick("brasov")} />

                    <Text text="AG" x={830} y={825} onClick={ this.handleJudetClick("arges")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={830} y={825}  anunturi={this.counters("arges").anunturi} licitatii={this.counters("arges").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("arges")} />

                    <Text text="TR" x={885} y={1030} onClick={ this.handleJudetClick("teleorman")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={885} y={1030}  anunturi={this.counters("teleorman").anunturi} licitatii={this.counters("teleorman").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("teleorman")} />

                    <Text text="NT" x={1030} y={430} onClick={ this.handleJudetClick("neamt")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1030} y={430}  anunturi={this.counters("neamt").anunturi} licitatii={this.counters("neamt").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("neamt")} />

                    <Text text="CV" x={1010} y={650} onClick={ this.handleJudetClick("covasna")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1010} y={650}  anunturi={this.counters("covasna").anunturi} licitatii={this.counters("covasna").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("covasna")} />

                    <Text text="DB" x={920} y={870} onClick={ this.handleJudetClick("dambovita")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={920} y={870}  anunturi={this.counters("dambovita").anunturi} licitatii={this.counters("dambovita").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("dambovita")} />

                    <Text text="PH" x={997} y={810} onClick={ this.handleJudetClick("prahova")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={997} y={810}  anunturi={this.counters("prahova").anunturi} licitatii={this.counters("prahova").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("prahova")} />

                    <Text text="IF" x={1020} y={945} onClick={ this.handleJudetClick("ilfov")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1020} y={945}  anunturi={this.counters("ilfov").anunturi} licitatii={this.counters("ilfov").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("ilfov")} />

                    <Text text="GR" x={990} y={1020} onClick={ this.handleJudetClick("giurgiu")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={990} y={1020}  anunturi={this.counters("giurgiu").anunturi} licitatii={this.counters("giurgiu").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("giurgiu")} />

                    <Text text="BT" x={1110} y={250} onClick={ this.handleJudetClick("botosani")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1110} y={250}  anunturi={this.counters("botosani").anunturi} licitatii={this.counters("botosani").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("botosani")} />

                    <Text text="IS" x={1180} y={370} onClick={ this.handleJudetClick("iasi")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1180} y={370}  anunturi={this.counters("iasi").anunturi} licitatii={this.counters("iasi").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("iasi")} />

                    <Text text="BC" x={1110} y={550} onClick={ this.handleJudetClick("bacau")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1110} y={550}  anunturi={this.counters("bacau").anunturi} licitatii={this.counters("bacau").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("bacau")} />

                    <Text text="VN" x={1135} y={670} onClick={ this.handleJudetClick("vrancea")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1135} y={670}  anunturi={this.counters("vrancea").anunturi} licitatii={this.counters("vrancea").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("vrancea")} />

                    <Text text="BZ" x={1105} y={780} onClick={ this.handleJudetClick("buzau")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1105} y={780}  anunturi={this.counters("buzau").anunturi} licitatii={this.counters("buzau").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("buzau")} />

                    <Text text="IL" x={1150} y={910} onClick={ this.handleJudetClick("ialomita")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1150} y={910}  anunturi={this.counters("ialomita").anunturi} licitatii={this.counters("ialomita").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("ialomita")} />

                    <Text text="CL" x={1150} y={980} onClick={ this.handleJudetClick("calarasi")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1150} y={980}  anunturi={this.counters("calarasi").anunturi} licitatii={this.counters("calarasi").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("calarasi")} />

                    <Text text="VS" x={1260} y={530} onClick={ this.handleJudetClick("vaslui")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1260} y={530}  anunturi={this.counters("vaslui").anunturi} licitatii={this.counters("vaslui").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("vaslui")} />

                    <Text text="GL" x={1260} y={680} onClick={ this.handleJudetClick("galati")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1260} y={680}  anunturi={this.counters("galati").anunturi} licitatii={this.counters("galati").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("galati")} />

                    <Text text="BR" x={1250} y={820} onClick={ this.handleJudetClick("braila")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1250} y={820}  anunturi={this.counters("braila").anunturi} licitatii={this.counters("braila").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("braila")} />

                    <Text text="TL" x={1400} y={840} onClick={ this.handleJudetClick("tulcea")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1400} y={840}  anunturi={this.counters("tulcea").anunturi} licitatii={this.counters("tulcea").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("tulcea")} />

                    <Text text="CT" x={1350} y={980} onClick={ this.handleJudetClick("constanta")} fill="#E6D2D1" fontSize={22} opacity={ this.textOpacity() } />
                    <CountersDesktop x={1350} y={980}  anunturi={this.counters("constanta").anunturi} licitatii={this.counters("constanta").licitatii}  opacity={ this.textOpacity() } onClick={ this.handleJudetClick("constanta")} />
                </Layer>
            </Stage>
        );

    } 
}


class Map extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            numarAnunturi: 0,
            numarAnunturiAzi: 0,
            numarLicitatii: 0,
            judetSelectat: "",
            judete: [],
            details: {
                judet: "selecteaza judet",
                jud_anunturi: 0,
                jud_licitatii: 0
            }
        }

    }

    sendEvent = () => {
        if(window.location.hostname === 'localhost' || window.location.hostname.indexOf('192.168.0') === 0) {
            return;
        }

        if(!sessionStorage.getItem('visit')) {
            sessionStorage.setItem('visit', true);

            fetch('https://api.ipify.org?format=json')
                .then((ipResp) => ipResp.json().then((ipJson) => {
                    fetch('https://09mclyfv7k.execute-api.eu-central-1.amazonaws.com/prod/log_event', {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({type: 'visit', name: 'licitatii.binaryfusion.ro', from: ipJson}),
                    }).then((response) => response.json().then((responseData) => {
                        sessionStorage.setItem('success', responseData.EventId);
                    }));
                })
            );
        }
    }



    componentDidMount() {
        this.sendEvent();

        /**
         * Judete este o lista cu obiecte de forma
         * nume = ""
         * anunturi = 0
         * licitatii = 0
         */
        JudeteDataService.getJudData().then( response => {
            JudeteDataService.lastResponse = response;

            // Resetez id-ul licitatiei selectate sa nu coloreze rand din lista totala de licitatii
            JudeteDataService.licitatiiAllSelected = 0;

            // Filtrez judetele care nu au nume
            let judete = response.judete
            judete = judete.filter(j => j.nume.trim() !== "")
            judete = judete.filter(j => j.nume.trim() !== "jud.")

            this.setState({
                dataScanare: response.data_scanare,
                numarAnunturi: response.numar_anunturi,
                numarAnunturiAzi: response.numar_anunturi_azi,
                numarLicitatii: response.numar_licitatii,
                licitatiiSalvate: response.licitatii_salvate,
                judete: judete
            });

            // Redeschid ultimul judet selectat. Cazul in care revin de la pagina de detalii: Licitatie.js
            if ( !!JudeteDataService.dropJud ) {
                this.onJudetClick( JudeteDataService.dropJud );
            }
        });
    }

    onJudetClick(jud) {
        let jud_data = this.state.judete.find( j => j.nume.indexOf(jud) !== -1)

        if ( !jud_data ) {
            jud_data = { anunturi: 0, licitatii: 0 }
        }

        this.setState({ 
            judetSelectat: jud,
            details: {
                judet: jud,
                jud_anunturi: jud_data.anunturi,
                jud_licitatii: jud_data.licitatii
            }
        });
    }

    onCloseDetails() {
        this.setState({
            judetSelectat: "",
            details: {
                judet: "",
                jud_anunturi: 0,
                jud_licitatii: 0
            }
        })
    }

    getJudetImage() {
        return "judete/" + this.state.judetSelectat + ".svg";
    }

    render() {

        return (
            <div>
                <Judete 
                    onClick={(jud) => this.onJudetClick(jud)} 
                    judete={this.state.judete}
                    anunturi={this.state.numarAnunturi}
                    licitatii={this.state.numarLicitatii}
                    judetSelectat={this.state.judetSelectat} />

                <div id="map-grid">
                    <div id="left">
                        <TotalAnunturi anunturi={this.state.numarAnunturi} anunturiAzi={this.state.numarAnunturiAzi} licitatii={this.state.numarLicitatii} />
                        <div id="wrapper-jud-image">
                            <div id="nume-jud">{this.state.judetSelectat.toUpperCase()}</div>
                            { this.state.judetSelectat !== "" && <img id="image-judet-selectat" src={ this.getJudetImage() } width={500} height={500} alt="judet selectat" /> }
                        </div>
                    </div>
                    
                    <div id="middle">
                         { this.state.judetSelectat !== "" && <LicitatiiSelectateDesktop judet={this.state.judetSelectat} anunturi={this.state.numarAnunturi} licitatii={this.state.numarLicitatii} onClose={() => this.onCloseDetails()}/> }
                    </div> 

                    <div id="right">
                        { this.state.judetSelectat !== "" && <JudDetailsPanel details={ this.state.details } onClose={() => this.onCloseDetails()} /> }
                    </div>
                </div>

            </div>
        );
    }
}

export default Map;